import { createStore } from 'vuex'
import { getCollectionList, getVideos } from '../firebase/firebaseUtil';

const store = createStore({
    state () {
      return {
        isLoading: false,
        collections : [],
        videos : [],
        searchResults : {},
        currentIndex: 0,
        playOption : {
            isInfinite: true,
            isRandom: false,
            oneInfinite: false,
        },
      }
    },
    mutations : {
        changeIsLoading(state, payload) {
            state.isLoading = payload.isLoading;
        },
        getCollections(state, payload) {
            state.collections = payload.collections;
        },
        getVideos(state, payload) {
            state.videos = payload.videos;
        },
        deleteVideo(state, payload) {
            const index = state.videos.findIndex((v)=>v.id === payload.id);
            if (index >= 0) {
                state.videos.splice(index, 1);
            }
        },
        changeCurrentIndex(state, payload) {
            state.currentIndex = payload.currentIndex;
        },
        changePlayOption(state, payload) {
            state.playOption = { ...payload.playOption };
        }
    },
    actions : {
        changeIsLoading(context, payload) {
            context.commit('changeIsLoading', payload);
        },
        async getCollections(context, payload) {
            let arr = await getCollectionList({ email: payload.email })
            context.commit('getCollections', { collections: arr });
        },
        async getVideos(context, payload) {
            let videos = await getVideos({ email:payload.email })
            context.commit('getVideos', { videos: videos });
        },
        deleteVideo(context, payload) {
            context.commit('deleteVideo', { id: payload.id });
        },
        changeCurrentIndex(context, payload) {
            context.commit('changeCurrentIndex', { currentIndex: payload.currentIndex });
        },
        changePlayOption(context, payload) {
            context.commit('changePlayOption', { playOption: payload.playOption });
        }
    }
})

export default store;