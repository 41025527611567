<template>
    <div class="container-fluid  ml-0 mr-0 border-top">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h6 style="color:orange;">Collections List</h6>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <swiper id="swiper" :slides-per-view="1" :space-between="50" navigation :pagination="{ clickable: true }" 
                        @slideChange="onSlideChange">
                        <swiper-slide v-for="(c) in collections" :key="c.id">
                            <div class="mb-5">
                                <h5>{{c.collname}} </h5>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
        <div class="mt-1 ml-0 mr-0 mb-5" >
            <div class="row">
                <div class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-2"  v-for="(v, index) in currentVideos" :key="v.id">
                    <div class="row mt-1 mb-1 border-top p-1">
                        <div class="col-5 col-sm-12">
                            <img :src="v.thumbnail" style="width:100%; cursor:pointer;" @click="playVideoList(index)" />
                            <button class="badge badge-secondary mr-1" @click="playVideoList(index)">
                                <i class="fa fa-play"></i>
                            </button>
                            <button class="badge badge-secondary mr-1" @click="deleteVideoFromList(v.id)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </div>
                        <div class="col-7 col-sm-12 mt-2 mb-2">
                            <div class="card-title txt_post"
                                v-html="v.title.length >= 20 ? v.title.substring(0,20)+'...' : v.title" />
                            <p class="card-text" v-if="false">
                                <small>
                                {{ v.description.length>=80 ? v.description.substring(0,80)+'...' : v.description }}
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center" v-if="currentVideos.length === 0">
                    <h3>영상 없음</h3>
                </div>
            </div>
        </div>
        <div class="col-12 text-center bg-dark" style="position:fixed; left:0px; bottom:0px; right:0px;">
            <button class="btn btn-info m-1" @click="router.push({ name: 'collections/add'})">
                <i class="fa fa-plus-square" style="cursor:pointer;" title="새로운 컬렉션 추가"></i> New 
            </button>
            <button class="btn btn-info m-1" @click="goTop">
                <i class="fa fa-angle-double-up" style="cursor:pointer;"></i> Top
            </button>
            <button class="btn btn-info m-1" @click="deleteCollectionFromList">
                <i class="fa fa-trash" style="cursor:pointer;" title="현재 컬렉션 삭제"></i> Delete
            </button>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
import { onMounted, computed } from 'vue'
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css'
import firebase from "firebase/app";
import { useStore } from 'vuex'
import { deleteVideo, deleteCollection } from '../firebase/firebaseUtil';
import { useRouter } from 'vue-router';

SwiperCore.use([Navigation, Pagination, Scrollbar]);

export default {
    name : "Collections",
    components: { Swiper, SwiperSlide },
    setup() {
        const store = useStore();
        const router = useRouter();

        const currentTabIndex = computed(()=>store.state.currentIndex);
        const collections = computed(()=>store.state.collections)
        const videosAll = computed(()=>store.state.videos);
        
        const current_collection_id = computed(()=> {
            if(collections.value[currentTabIndex.value])
                return collections.value[currentTabIndex.value].id;
            return "";
        });

        const currentVideos = computed(()=> {
            if (current_collection_id.value !== "") 
                return videosAll.value.filter((v)=>v.collections_id === current_collection_id.value);
            return [];
        });

        const goTop = ()=> {
            window.scrollTo(0,0)
        }

        const deleteVideoFromList = async (id) => {
            if (window.confirm('정말로 이 영상을 삭제합니까?') === true) {
                await deleteVideo({id});
                store.dispatch('deleteVideo', { id:id });
            }
        }

        const deleteCollectionFromList = async ()=> {
            if (collections.value.length > 0) {
                if (window.confirm('정말로 컬렉션을 삭제합니까?') === true) {
                    await deleteCollection({ id: current_collection_id });
                }
            }
        }

        const onSlideChange = (swiper)=>{
            store.dispatch('changeCurrentIndex', { currentIndex: swiper.activeIndex });
        }

        const playVideoList = (index)=> {
            router.replace({ name:"collections/play", params: { index } })
        }

        onMounted(()=>{
            firebase.auth().onAuthStateChanged(async (user) => {
                if (user) {
                    if (collections.value.length === 0) {
                        await store.dispatch('getCollections', { email: user.email })
                        await store.dispatch('getVideos', { email: user.email })
                    }
                }
            })
            document.querySelector("#swiper").swiper.slideTo(currentTabIndex.value, 0);
        });

        return { onSlideChange, collections, currentVideos, goTop, deleteVideoFromList, 
            deleteCollectionFromList, playVideoList, router, currentTabIndex }
  }
}
</script>
<style scoped>


</style>