<template>
<div class="container pt-3 border pt-2 mt-0 pb-5">
  <div class="row justify-content-sm-center">
    <div class="col-sm-12 col-md-9 col-lg-6">
      <div class="card border-info text-center">
        <div class="card-header">
          로그인
        </div>
        <div class="card-body">
            <input type="text" class="form-control mb-2" placeholder="Email" v-model="data.email" required autofocus>
            <input type="password" class="form-control mb-2" placeholder="Password" 
              v-model="data.password" @keyup.enter="loginProcess" required>
            <button class="btn btn-lg btn-primary btn-block mb-1" @click="loginProcess">로그인</button>
        </div>
      </div>
      <router-link to="/register" class="float-right">새로운 사용자 등록</router-link>
    </div>
  </div>
</div>
</template>

<script>
import { reactive } from 'vue'
import firebase from "firebase/app";
import { useRouter, useRoute } from 'vue-router';
import { changeIsLoading, useFirebaseLoading } from '../firebase/firebaseUtil';

export default {
    name : "SignIn",
    setup() {
      const router = useRouter();
      const currentRoute = useRoute();
      const data = reactive({ email:"", password:"", remember:false })
      
      useFirebaseLoading()
      
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          if (currentRoute.query.fromname) {
            router.replace({ name:currentRoute.query.fromname })
          } else {
            router.replace({ name:'home' })
          } 
        }
      })

      const loginProcess = () => {
        changeIsLoading(true);
        firebase.auth().signInWithEmailAndPassword(data.email, data.password)
        .then(() => {
          changeIsLoading(false);
          console.log(currentRoute.query)
          if (currentRoute.query.fromname) {
            router.replace({ name: currentRoute.query.fromname });
          } else {
            router.replace({ name:'home' });
          }
        })
        .catch((error) => {
          changeIsLoading(false);
          alert("로그인 실패 : " + error.message)
        });
      }

      return { data, loginProcess }
    }
}
</script>

<style>

</style>