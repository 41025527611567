<template>
<nav class="navbar fixed-top navbar-expand-sm bg-dark navbar-dark m-0">
    <img src="@/assets/youtube.png" class="mr-2 pointer"  @click="navigate('home')" />
    <span class="navbar-brand">MyYoutube</span>
    <button class="navbar-toggler" type="button" @click="changeIsNavShow">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="m-0" :class="navClass">
        <ul class="navbar-nav">
            <li class="nav-item">
                <span class="nav-link" @click="navigate('home')" style="cursor:pointer;">홈</span>
            </li>
            <li class="nav-item">
                <span class="nav-link" @click="navigate('login')" style="cursor:pointer;">로그인</span>
            </li>
            <li class="nav-item">
                <span class="nav-link" @click="navigate('collections')" style="cursor:pointer;">영상컬렉션</span>
            </li>
            <li class="nav-item">
                <span class="nav-link" @click="navigate('search')" style="cursor:pointer;">유튜브검색</span>
            </li>
        </ul>
    </div>
</nav>
</template>

<script>
import { reactive, computed } from 'vue';
import { useRouter } from 'vue-router';

export default {
    setup() {
        const router = useRouter();
        const state = reactive({
            isNavShow: false, user: null
        })

        const navClass = computed(() => state.isNavShow ? "collapse navbar-collapse show" : "collapse navbar-collapse")
        const changeIsNavShow = () => {
            state.isNavShow = !state.isNavShow;
        } 
        const navigate = (pathname) => {
            state.isNavShow = false;
            router.replace({ name: pathname });
        }
        
        return { state, navClass, changeIsNavShow, navigate };
    }
}
</script>
<style></style>