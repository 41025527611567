<template>
  <div class="modal">
    <div class="box">
      <div class="heading">
        <span class="title">{{ videoInfo.title }}</span>
        <span class="float-right badge badge-secondary pointer" @click="stopVideo">
          X
        </span>
      </div>
      <div class="player">
        <YoutubeVue3
          ref="playerRef"
          :videoid="videoInfo.youtube_videoid"
          :width="state.width"
          :height="state.height"
          :autoplay="1"
          @ended="onPlayerEnded"
          :modestbranding="1"
          :controls="1"
        />
      </div>
      <div>
        <span
          class="badge p-1 m-0 mr-2 border-0 pointer rounded-0"
          :class="playOption.isRandom ? 'badge-success' : 'badge-light'"
          v-if="!playOption.oneInfinite"
          @click="changeIsRandom(!playOption.isRandom)"
        >
          <img class="play-button mr-2" src="@/assets/shuffle.svg" />
          <small>랜덤{{ playOption.isRandom ? "(O)" : "(X)" }}</small>
        </span>
        <span
          class="badge p-1  m-0 mr-2 border-0 pointer rounded-0"
          :class="playOption.isInfinite ? 'badge-success' : 'badge-light'"
          v-if="!playOption.oneInfinite"
          @click="changeIsInfinite(!playOption.isInfinite)"
        >
          <img class="play-button mr-2" src="@/assets/repeat-button.svg" />
          <small>모두 반복{{ playOption.isInfinite ? "(O)" : "(X)" }}</small>
        </span>
        <span
          class="badge p-1 m-0 mr-2 border-0 pointer rounded-0"
          :class="playOption.oneInfinite ? 'badge-success' : 'badge-light'"
          @click="changeOneInfinite(!playOption.oneInfinite)"
        >
          <img class="play-button mr-2" src="@/assets/circle.svg" />
          <small>현재 영상 반복 {{ playOption.oneInfinite ? "(O)" : "(X)" }}</small>
        </span>
        <div class="card card-body">
          <span
            v-for="(v, index) in videosAll"
            :key="v.id"
            class="badge rounded-circle mr-1 pointer p-2"
            :class="index === parseInt(currentRoute.params.index) ? 'badge-primary' : 'badge-light'"
            :title="v.title"
            @click="router.replace({ name: 'collections/play', params: { index } })"
          >
            <small>{{ getNumber(index) }}</small>
          </span>
        </div>
      </div>
      <div>
        <div class="text-center">
          <button class="badge badge-secondary m-1" @click="playPrev" title="이전 영상">
            <i class="fa fa-backward"></i>
          </button>
          <button class="badge badge-secondary m-1" @click="stopVideo" title="중지">
            <i class="fa fa-stop"></i>
          </button>
          <button class="badge badge-secondary m-1" @click="playNext" title="다음 영상">
            <i class="fa fa-forward"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, reactive, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
//import { YoutubeVue3 } from "youtube-vue3";
import YoutubeVue3 from "@/components/YoutubeVue3";

export default {
  name: "PlayList",
  components: { YoutubeVue3 },
  setup() {
    const playerRef = ref();
    const store = useStore();
    const router = useRouter();
    const currentRoute = useRoute();
    const state = reactive({ width: 630, height: 470 });

    if (window.innerWidth <= 850) {
      state.width = 320;
      state.height = 220;
    } else if (window.innerWidth <= 1280) {
      state.width = 480;
      state.height = 315;
    } else {
      state.width = 630;
      state.height = 470;
    }

    const playOption = computed(() => store.state.playOption);
    const collection_id = computed(() => store.state.collections[store.state.currentIndex].id);
    const videosAll = computed(() => store.state.videos.filter((v) => v.collections_id === collection_id.value));
    let videoInfo = computed(() => videosAll.value[currentRoute.params.index]);

    onBeforeRouteUpdate((to) => {
      videoInfo = videosAll.value[to.params.index];
      playerRef.value.player.playVideo();
    });

    const onWindowResize = () => {
      const player = document.getElementById("youtube-vue-player-soma");
      if (window.innerWidth <= 850) {
        state.width = 320;
        state.height = 220;
        player.width = 320;
        player.height = 220;
      } else if (window.innerWidth <= 1280) {
        state.width = 480;
        state.height = 315;
        player.width = 480;
        player.height = 315;
      } else {
        state.width = 630;
        state.height = 470;
        player.width = 630;
        player.height = 470;
      }
    };

    onMounted(() => {
      window.addEventListener("resize", onWindowResize);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", onWindowResize);
    });

    const playPrev = () => {
      const index = parseInt(currentRoute.params.index, 10);
      if (playOption.value.oneInfinite === true) {
        playerRef.value.player.stopVideo();
        playerRef.value.player.playVideo();
      } else if (playOption.value.isRandom === false) {
        if (index === 0) {
          if (playOption.value.isInfinite) {
            router.replace({ name: "collections/play", params: { index: videosAll.value.length - 1 } });
          } else {
            stopVideo();
          }
        } else {
          router.replace({ name: "collections/play", params: { index: index - 1 } });
        }
      } else {
        let randomIndex = Math.floor(Math.random() * videosAll.value.length);
        while (randomIndex === index) {
          randomIndex = Math.floor(Math.random() * videosAll.value.length);
        }
        router.replace({ name: "collections/play", params: { index: randomIndex } });
      }
    };
    const playNext = () => {
      const index = parseInt(currentRoute.params.index, 10);
      if (playOption.value.oneInfinite === true) {
        playerRef.value.player.stopVideo();
        playerRef.value.player.playVideo();
      } else if (playOption.value.isRandom === false) {
        if (index === videosAll.value.length - 1) {
          if (playOption.value.isInfinite) {
            router.replace({ name: "collections/play", params: { index: 0 } });
          } else {
            stopVideo();
          }
        } else {
          router.replace({ name: "collections/play", params: { index: index + 1 } });
        }
      } else {
        let randomIndex = Math.floor(Math.random() * videosAll.value.length);
        while (randomIndex === index) {
          randomIndex = Math.floor(Math.random() * videosAll.value.length);
        }
        router.replace({ name: "collections/play", params: { index: randomIndex } });
      }
    };

    const stopVideo = () => {
      console.log(playerRef);
      playerRef.value.player.stopVideo();
      router.replace({ name: "collections" });
    };

    const onPlayerEnded = () => {
      if (playOption.value.isInfinite === true) {
        playNext();
      } else {
        stopVideo();
      }
    };
    const changeOneInfinite = (oneInfinite) => {
      store.dispatch("changePlayOption", { playOption: { ...store.state.playOption, oneInfinite } });
    };

    const changeIsRandom = (isRandom) => {
      if (isRandom) store.dispatch("changePlayOption", { playOption: { ...store.state.playOption, isRandom, isInfinite: true } });
      else
        store.dispatch("changePlayOption", {
          playOption: { ...store.state.playOption, isRandom, isInfinite: playOption.value.isInfinite },
        });
    };
    const changeIsInfinite = (isInfinite) => {
      store.dispatch("changePlayOption", { playOption: { ...store.state.playOption, isInfinite } });
    };

    const getNumber = (index) => {
      return index < 9 ? "0" + (index + 1) : index + 1;
    };

    return {
      router,
      currentRoute,
      videosAll,
      playerRef,
      videoInfo,
      state,
      playOption,
      stopVideo,
      playNext,
      playPrev,
      changeIsRandom,
      changeIsInfinite,
      changeOneInfinite,
      getNumber,
      onPlayerEnded,
    };
  },
};
</script>

<style>
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.box {
  background-color: white;
  margin: 60px auto;
  max-width: 640px;
  min-width: 100px;
  min-height: 250px;
  font: 12px "verdana";
  z-index: 99;
  padding: 5px 5px 5px 5px;
}
.box div {
  padding: 0;
  display: block;
  margin: 10px 0 0 0;
}
.box .heading {
  background: #33a17f;
  font-weight: 300;
  text-align: left;
  color: #fff;
  margin: 0px;
  padding: 10px 10px 10px 10px;
  min-width: 200px;
  max-width: 640px;
}
.box .player {
  background: white;
}
.pointer {
  cursor: pointer;
}
.play-button {
  width: 15px;
  height: 15px;
}
.play-button-disabled {
  opacity: 0.3;
}
@media screen and (max-width: 850px) {
  .box {
    background-color: white;
    margin: 60px auto;
    max-width: 330px;
    min-width: 100px;
    min-height: 250px;
    font: 12px "verdana";
    padding: 5px 5px 5px 5px;
  }
  .box div {
    padding: 0;
    display: block;
    margin: 5px 0 0 0;
  }
  .box .heading {
    background: #33a17f;
    font-weight: 300;
    text-align: left;
    color: #fff;
    margin: 0px;
    padding: 10px 10px 10px 10px;
    min-width: 200px;
    max-width: 360px;
  }
}

@media screen and (min-width: 850px) and (max-width: 1280px) {
  .box {
    background-color: white;
    margin: 60px auto;
    max-width: 490px;
    min-width: 100px;
    min-height: 250px;
    font: 12px "verdana";
    padding: 5px 5px 5px 5px;
  }
  .box div {
    padding: 0;
    display: block;
    margin: 5px 0 0 0;
  }
  .box .heading {
    background: #33a17f;
    font-weight: 300;
    text-align: left;
    color: #fff;
    margin: 0px;
    padding: 10px 10px 10px 10px;
    min-width: 200px;
    max-width: 520px;
  }
}
</style>
