<template>
<div class="container pt-3 border pt-2 mt-0 pb-5">
  <div class="row justify-content-sm-center">
    <div class="col-sm-12 col-md-9 col-lg-6">
      <div class="card border-info text-center">
        <div class="card-header">
          회원 가입
        </div>
        <div class="card-body">
          <input type="text" class="form-control mb-2" placeholder="Email" v-model.trim="data.email" required autofocus>
          <input type="password" class="form-control mb-2" placeholder="Password" v-model.trim="data.password1" required>
          <input type="password" class="form-control mb-2" placeholder="Password" v-model.trim="data.password2" required>
          <div class="row">
              <div class="col-6">
                  <button class="btn btn-lg btn-primary btn-block mb-1" @click="registerUser">등록</button>
              </div>
              <div class="col-6">
                  <button class="btn btn-lg btn-primary btn-block mb-1" @click="cancel">취소</button>
              </div>
          </div>
        </div>
      </div>
      <router-link to="/login" class="float-right">로그인 화면으로</router-link>
    </div>
  </div>
</div>
</template>

<script>
import { reactive } from 'vue'
import firebase from "firebase/app";
import { useRouter } from 'vue-router';
import { addCollection, addVideo } from '../firebase/firebaseUtil';

export default {
  name : "Register",
  setup() {
    const router = useRouter();
    const data = reactive({ email:"", password1:"", password2:"" })
    const registerUser = async () => {
      if (data.password1 !== data.password2) {
        alert("두 암호가 일치하지 않습니다.");
        return;
      }
      if (data.password1.length < 6) {
        alert("암호는 6글자 이상으로 입력합니다.");
        return;
      }

      try {
        await firebase.auth().createUserWithEmailAndPassword(data.email, data.password1)
        const collections_id1 = await addCollection({ email: data.email, collname:"기본 리스트" })
        await addVideo({ 
          email:data.email, 
          collections_id: collections_id1, 
          youtube_videoid:"7QDfNGcjsyY", 
          title:"🤟이것이 조선의 힙이다🤟전 세계를 놀래킨 21세기 판소리 #이날치 #LEENALCHI 노래모음 | 범 내려온다 좌우나졸 어류도감 ; LIVE 풀버전_문화콘서트 난장",
          description:"이날치 LEENALCHI 노래모음 ; LIVE 풀버전_문화콘서트 난장 자막 ver. https://www.youtube.com/watch?v=tGMcLlnqjIE Real live NANJANG MBC 문화콘서트 ...",
          thumbnail:"https://i.ytimg.com/vi/7QDfNGcjsyY/default.jpg"
        })
        alert("사용자 등록에 성공하였습니다.");  
        router.replace({ name:'login' }); 
      } catch(error) {
        alert("사용자 등록 실패 : " + error)
      }
    }

    const cancel = () => {
      router.replace({ name:'login' });
    }

    return { data, registerUser, cancel };
  }
}
</script>

<style>

</style>