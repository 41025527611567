import firebase from "firebase/app";
import store from '../store'

const useFirebaseLoading = () => {
    changeIsLoading(true);
    firebase.auth().onAuthStateChanged(() => {
        changeIsLoading(false);
    });
}

const changeIsLoading = (isLoading) => {
    store.dispatch("changeIsLoading", { isLoading });
}

const getUser = () => {
    return firebase.auth().currentUser;
}

const addCollection = async ({ email, collname })=> {
    changeIsLoading(true);
    var db = firebase.firestore();
    const res = await db.collection("collections").add({ email, collname, timestamp: new Date().getTime() })
    await store.dispatch('getCollections', { email: email })
    changeIsLoading(false);
    return res.id;
}

const addVideo = async ({ email, collections_id, youtube_videoid, title, description, thumbnail }) => {
    var db = firebase.firestore();
    changeIsLoading(true);
    const res = await db.collection("videos").add({
        email, collections_id, youtube_videoid, title, description, thumbnail,
        timestamp: new Date().getTime()
    })
    await store.dispatch('getVideos', { email: email })
    changeIsLoading(false);
    return res.id;
}

const getCollectionList = async ({ email }) => {
    var db = firebase.firestore();
    const snapshot = await db.collection("collections").where("email", "==", email).orderBy('timestamp').get();
    let arr = [];
    snapshot.forEach((doc) => {
        arr.push({ id:doc.id, email:doc.data().email, collname:doc.data().collname, timestamp:doc.data().timestamp })
    });
    return arr;
}

const getVideos = async ({ email }) => {
    var db = firebase.firestore();
    const snapshot = await db.collection("videos").where("email", "==", email).orderBy('timestamp').get();
    let arr = [];
    snapshot.forEach((doc) => {
        let { email, collections_id, youtube_videoid, title, description, thumbnail } = doc.data();
        arr.push({ id:doc.id, email, collections_id, youtube_videoid, title, description, thumbnail, timestamp:doc.data().timestamp })
    })
    return arr;
}

const deleteVideo = async ({id}) => {
    var db = firebase.firestore();
    changeIsLoading(true);
    await db.collection("videos").doc(id).delete();
    changeIsLoading(false)
}

const deleteCollection = async ({id}) => {
    const db = firebase.firestore();
    const user = firebase.auth().currentUser;
    changeIsLoading(true);
    const videos = store.state.videos.filter((v)=>v.collections_id === id.value);
    for (let i=0; i < videos.length; i++) {
        await deleteVideo({ id: videos[i].id });
    }
    await db.collection("collections").doc(id.value).delete();
    await store.dispatch('getCollections', { email: user.email })
    await store.dispatch('getVideos', { email: user.email })
    changeIsLoading(false);
}

export { 
    useFirebaseLoading, getUser, changeIsLoading, addCollection, 
    addVideo, getCollectionList, getVideos, 
    deleteVideo, deleteCollection
};