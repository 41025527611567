<template>
  <div class="container-fluid ml-0 mr-0">
    <div class="row card card-body" v-if="state.user" @click="router.replace({name:'collections'})" style="cursor:pointer;">
      <div class="col-12">
        <h5 style="color:orange;">현재 {{collections.length}}개의 영상 컬렉션에 {{videos.length}}개의 영상을 수집하셨습니다.</h5>
      </div>
      <div  class="col-12" v-for="c in collections" :key="c.no">
          ✔ {{c.collname}} : {{videos.filter((v)=>v.collections_id === c.id).length}}개의 영상
      </div>
    </div>
    <div v-else>
      <div class="row card card-body">
        <div class="col-12 ">
          <h5 style="color:orange;">로그인하지 않았습니다.</h5>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <!-- Markdown 시작 -->
      <div class="card card-body bg-dark text-light">
        <h3>사용법</h3>
      </div>
      <div class="p-1 pt-3">
      <p>이 앱은 유튜브 영상을 검색하여 내 즐겨찾기(컬렉션)에 추가하여 플레이할 수 있도록 만들었습니다.</p>
      <hr />
      <h3>새로운 사용자 등록</h3>
      <p>로그인 화면에서 '새로운 사용자 등록'으로 이동하여 이메일 주소와 비밀번호만으로 등록합니다.<br>
      사용자 등록이 완료되면 로그인해주세요.</p><hr />
      <h3>영상 컬렉션</h3>
      <p>회원 등록한 직후에는 영상 컬렉션이 하나 밖에 없습니다.</p>
      <h5 style="border-bottom:dashed 1px gray;">새로운 컬렉션 만들기</h5>
      <p>영상 컬렉션 메뉴로 이동하여 화면 가장 하단의 New 버튼을 클릭하여 새로운 컬렉션을 추가합니다.</p>
      <img src="@/assets/collections3.png" style="width:300px;"/>
      <h5 style="border-bottom:dashed 1px gray;">기존 컬렉션 삭제하기</h5>
      <p>영상 컬렉션 메뉴로 이동하여 화면 가장 하단의 Delete 버튼을 클릭하여 컬렉션을 삭제합니다. 이 때 컬렉션 내의 모든 영상 정보들이 함께 삭제됩니다.</p>
      <h5 style="border-bottom:dashed 1px gray;">컬렉션에서 특정 영상 삭제하기</h5>
      <p>그림의 쓰레기통 버튼을 이용해 삭제합니다.</p>
      <img src="@/assets/collections1.png" style="width:300px;" />
      <h5 style="border-bottom:dashed 1px gray;">컬렉션에서 영상 플레이하기</h5>
      <p>앞의 이미지의 플레이 버튼을 클릭하면 다음과 같은 화면이 나타나면서 영상을 플레이합니다.</p>
      <img src="@/assets/collections2.png" style="width:300px;" />
      <ul>
      <li>무한 재생 : 이 체크박스만 체크되어 있으면 영상 순서대로 무한 재생합니다.</li>
      <li>무작위 재생 : 이것을 체크하면 랜덤하게 영상을 무한 재생합니다.</li>
      <li>숫자 버튼 : 숫자 버튼을 영상의 순서를 의미합니다. 이 버튼을 클릭하여 직접 특정번째 영상을 플레이합니다.</li>
      </ul>
      <hr />
      <h3>유튜브 검색</h3>
      <p>이곳에서 유튜브 영상을 검색하고 내 컬렉션에 추가합니다.</p>
      <h5 style="border-bottom:dashed 1px gray;">검색하기</h5>
      <p>텍스트박스에 키워드를 입력하고 검색합니다. 검색 결과는 한번에 20개씩 출력됩니다. 다음 20개를 보시려면 화면 아래의 Next 버튼을 이용합니다. 화면의 가장 상단으로 이동하려면 Top을 누르세요.</p>
      <h5 style="border-bottom:dashed 1px gray;">내 컬렉션에 추가하기</h5>
      <p>영상마다 내 컬렉션에 추가하기 버튼이 있습니다. 이것을 클릭하여 영상 컬렉션에 추가할 수 있습니다.</p>
      <img src="@/assets/search1.png" style="width:300px;" />
      </div>
      <!-- Makrdown 끝 -->
    </div>
  </div>
</template>

<script>
import { useFirebaseLoading } from '@/firebase/firebaseUtil';
import { onMounted, computed,reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router';
import firebase from "firebase/app";

export default {
  name : "Home",
  setup() {
    const router = useRouter();
    const store = useStore();
    useFirebaseLoading();
    const collections = computed(()=>store.state.collections);
    const videos = computed(()=>store.state.videos);
    const state = reactive({ user : null })

    onMounted(()=>{
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          if (!collections.value || collections.value.length === 0) {
            await store.dispatch('getCollections', { email: user.email })
            await store.dispatch('getVideos', { email: user.email })
          }
          state.user = user;
        } else {
          state.user = null;
        }
      })
    });

    return { state, collections, videos, router }
  }
}
</script>
<style scoped>

</style>
