<template>
<div class="modal">
    <div class="box">
        <div class="heading">
            <span class="title">:: 컬렉션에 영상 추가</span>
            <span class="float-right badge badge-secondary pointer" @click="goToSearch">
                X
            </span>
        </div>
        <div>
            <div class="form-group p-2">
                <label class="title">컬렉션 선택 : </label>
                <select class="form-control" v-model="state.selectedIndex">
                    <option v-for="(c, index) in collections" :key="c.id" :value="index">
                        {{c.collname}}
                    </option>
                </select>
                <button class="btn btn-info m-1" @click="addVideoToCollection">추 가</button>
                <button class="btn btn-info m-1" @click="goToSearch">닫 기</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { computed, reactive } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex';
import { addVideo } from '@/firebase/firebaseUtil';
import firebase from "firebase/app";

export default {
    name:"AddToMyCollection",
    props : ['searchResults'],
    setup(props) {
        const store = useStore();
        const router = useRouter();
        const currentRoute = useRoute();

        const state = reactive({ selectedIndex :0 });
        const currentTabIndex = computed(()=>store.state.currentIndex);
        state.selectedIndex = currentTabIndex.value;

        const videoid = currentRoute.params.videoid;
        const currentItem = props.searchResults.items.find((item)=>item.id.videoId === videoid);
        const collections = computed(()=>store.state.collections);
        if (collections.value.length === 0) {
            const user = firebase.auth().currentUser;
            store.dispatch('getCollections', { email: user.email })
        }

        const goToSearch = () => {
            router.replace({ name:"search" })
        }

        const addVideoToCollection = async () => {
            const currentCollection = collections.value[state.selectedIndex];
            const currentVideos = store.state.videos.filter((v)=>v.collections_id === currentCollection.id);
            if (currentVideos.length >= 60) {
                alert('한 컬렉션에 저장할 수 있는 최대 영상의 수는 60개입니다.');
                return;
            }
            const user = firebase.auth().currentUser;
            await addVideo({ 
                email : user.email,
                collections_id: currentCollection.id, 
                youtube_videoid: videoid, 
                title: currentItem.snippet.title,
                description: currentItem.snippet.description, 
                thumbnail: currentItem.snippet.thumbnails.medium.url
            })
            router.replace({ name:"search" })
        }

        return { state, collections,  addVideoToCollection, goToSearch, currentTabIndex }
    }
}
</script>

<style scoped>
.modal { display: block; position: fixed; z-index: 1; 
    left: 0; top: 0; width: 100%; height: 100%;
    overflow: auto; background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); }
.box { background-color: white; margin:80px auto;
    max-width: 300px; min-width: 100px; min-height: 200px;
    font: 13px "verdana";
    padding: 5px 5px 5px 5px;  }
.box div { padding: 0;  display: block;  margin: 10px 0 0 0; }
.box .heading { background: #33A17F; font-weight: 300;
    text-align: left; color: #fff;
    margin:0; padding: 10px; min-width:200px;
    max-width:500px; }
.box .player { background:white; }
.title { text-align: left; }
.pointer { cursor:pointer; }
</style>