<template>
<div class="modal">
    <div class="box">
        <div class="heading">
            <span class="title">:: 새 컬렉션 추가</span>
            <span class="float-right badge badge-secondary pointer" @click="goToCollectionList">
                X
            </span>
        </div>
        <div>
            <div class="form-group p-2">
                <label class="title">컬렉션명 입력 : </label>
                <input type="text"  class="form-control" v-model="state.collname" />
                <button class="btn btn-info mt-2" @click="addToList">추 가</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { addCollection, getCollectionList } from '../../firebase/firebaseUtil';
import firebase from "firebase/app";
import { useStore } from 'vuex';

export default {
    name:"AddCollection",
    setup() {
        const router = useRouter();
        const state = reactive({ collname:"" });
        const store = useStore();

        const addToList = async () => {
            if (store.state.collections.length >= 12) {
                alert('영상 컬렉션은 12개까지만 생성할 수 있습니다.');
                return;
            }
            const user = firebase.auth().currentUser;
            await addCollection({ email: user.email, collname:state.collname });
            await getCollectionList({ email:user.email })
            router.replace({ name:'collections' });
        }
        const goToCollectionList = () => {
            router.replace({ name:'collections' });
        }
        return { state, goToCollectionList, addToList };
    }
}
</script>

<style scoped>
.modal { display: block; position: fixed; z-index: 1; 
    left: 0; top: 0; width: 100%; height: 100%;
    overflow: auto; background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); }
.box { background-color: white; margin:80px auto;
    max-width: 300px; min-width: 100px; min-height: 200px;
    font: 13px "verdana";
    padding: 5px 5px 5px 5px;  }
.box div { padding: 0;  display: block;  margin: 10px 0 0 0; }
.box .heading { background: #33A17F; font-weight: 300;
    text-align: left; color: #fff;
    margin:0; padding: 10px; min-width:200px;
    max-width:500px; }
.title { text-align: left; }
.pointer { cursor:pointer; }
</style>