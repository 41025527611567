<template>
<div class="modal">
    <div class="box">
        <div class="heading">
            <span style="margin-right:20px; " v-html="state.currentItem.snippet.title"></span>
            <span class="float-right badge badge-secondary pointer" @click="stopVideo">
                X
            </span>
        </div>
        <div class="player">
            <YoutubeVue3 ref="playerRef" :width="state.width" :height="state.height" :videoid="state.currentItem.id.videoId"
                :autoplay="1" @ended="stopVideo" />
        </div>
        <div class="text-center">
            <button class="btn btn-info mr-1" @click="goAddToMyCollection(state.currentItem.id.videoId)">
                내 컬렉션에 추가
            </button>
            <button class="btn btn-info" @click="stopVideo">닫 기</button>
        </div>
    </div>
</div>
</template>

<script>
import { ref, reactive, onUnmounted, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { YoutubeVue3} from 'youtube-vue3'

export default {
    name : "PlayYoutube",
    components : { YoutubeVue3 },
    props : ['searchResults'],
    setup(props) {
        const playerRef = ref(null) 
        const currentRoute = useRoute();
        const router = useRouter();
        const videoid = currentRoute.params.videoid;

        const state = reactive({ currentItem : {}, width:630, height:470 })
        if (window.innerWidth <= 850) {
            state.width = 320; 
            state.height=220;
        } else if (window.innerWidth <= 1280) {
            state.width = 480;
            state.height= 315;
        } else {
            state.width = 630;
            state.height= 470;
        }

        state.currentItem = props.searchResults.items.find((item)=>item.id.videoId === videoid);
        
        const stopVideo = () => {
            playerRef.value.player.stopVideo()
            router.replace({ name:'search' });
        }
        const goAddToMyCollection = (videoid) => {
            router.replace({ name:"search/add", params: { videoid }})
        }

        const onWindowResize = ()=>{
            const player = document.getElementById('youtube-vue-player-soma')
            if (window.innerWidth <= 850) {
                state.width = 320; state.height=220;
                player.width = 320; player.height= 220;
            } else if (window.innerWidth <= 1280) {
                state.width = 480; state.height=315;
                player.width = 480; player.height= 315;
            } else {
                state.width = 630; state.height=470;
                player.width = 630; player.height= 470;
            }
        }

        onMounted(()=>{
            window.addEventListener('resize', onWindowResize);
        })

        onUnmounted(()=>{
            window.removeEventListener('resize', onWindowResize);
        })


        return { stopVideo, state, playerRef, goAddToMyCollection }
    }
}
</script>

<style>
.modal { display: block; position: fixed; z-index: 1; 
    left: 0; top: 0; width: 100%; height: 100%;
    overflow: auto; background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); }
.box { background-color: white; margin:80px auto;
    max-width: 640px; min-width: 100px; min-height: 250px;
    font: 12px "verdana";
    padding: 5px 5px 5px 5px;  }
.box div { padding: 0;  display: block;  margin: 5px 0 0 0; }
.box .heading { background: #33A17F; font-weight: 300;
    text-align: left; color: #fff;
    margin:0px; padding: 10px 10px 10px 10px; min-width:200px;
    max-width:640px; }
.box .player { background:white; }
.pointer { cursor:pointer; }
@media screen and (max-width: 850px) {
    .box { background-color: white; margin:60px auto;
        max-width: 330px; min-width: 100px; min-height: 250px;
        font: 12px "verdana";
        padding: 5px 5px 5px 5px;  }
    .box div { padding: 0;  display: block;  margin: 5px 0 0 0; }
    .box .heading { background: #33A17F; font-weight: 300;
        text-align: left; color: #fff;
        margin:0px; padding: 10px 10px 10px 10px; min-width:200px;
        max-width:360px; }
}

@media screen and (min-width:850px) and (max-width: 1280px) {
    .box { background-color: white; margin:60px auto;
        max-width: 490px; min-width: 100px; min-height: 250px;
        font: 12px "verdana";
        padding: 5px 5px 5px 5px;  }
    .box div { padding: 0;  display: block;  margin: 5px 0 0 0; }
    .box .heading { background: #33A17F; font-weight: 300;
        text-align: left; color: #fff;
        margin:0px; padding: 10px 10px 10px 10px; min-width:200px;
        max-width:520px; }
}

</style>