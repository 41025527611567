<template>
  <div class="m-1">
    <Header />
    <div class="pt-3 mt-5 pt-2 m-0 pb-3">
      <LoginBadge v-if="currentRoute.name !== 'login' && currentRoute.name !== 'register'"/>
    </div>
    <router-view></router-view>
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import Header from '@/components/Header.vue'
import Loading from '@/components/Loading.vue'
import LoginBadge from '@/components/LoginBadge'

export default {
  name : "App",
  components : { Header, Loading, LoginBadge },
  setup() {
    const store = useStore();
    const currentRoute = useRoute();
    return {
      isLoading: computed(()=>store.state.isLoading),
      currentRoute,
    }
  }
}
</script>
<style>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css");
.container { text-align: center; margin-top:10px; }
</style>