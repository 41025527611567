<template>
<div class="modal">
    <div class="box">
        <div class="heading">
            <span class="title">:: 컬렉션에 영상 추가</span>
            <span class="float-right badge badge-secondary pointer" @click="goToSearch">
                X
            </span>
        </div>
        <div>
            <div class="form-group p-2">
                <label class="title">VideoId : </label>
                <input type="text"  class="form-control" v-model="state.youtube_videoid" />
                <label class="title">컬렉션 선택 : </label>
                <select class="form-control" v-model="state.selectedIndex">
                    <option v-for="(c, index) in collections" :key="c.id" :value="index">
                        {{c.collname}}
                    </option>
                </select>
                <button class="btn btn-info m-1" @click="searchAndAddVideoById">추 가</button>
                <button class="btn btn-info m-1" @click="goToSearch">닫 기</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { reactive,computed } from 'vue'
import axios from 'axios'
import { youtubeApiKey } from '@/api.config.js'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { addVideo } from '@/firebase/firebaseUtil';
import firebase from "firebase/app";
import { useFirebaseLoading } from '@/firebase/firebaseUtil';

export default {
    name:"AddVideoById",
    setup() {
        useFirebaseLoading();
        const router = useRouter();
        const store = useStore();
        if (store.state.collections.length === 0) {
            router.replace({ name: 'home' })
            return;
        } 
        const user = firebase.auth().currentUser;
        const currentTabIndex = computed(()=>store.state.currentIndex);
        const collections = computed(()=>store.state.collections);
        const currentCollection = collections.value[currentTabIndex.value];

        const state = reactive({ 
            selectedIndex : currentTabIndex.value,
            apiKey : "",
            email : user.email,
            collections_id: currentCollection.id, 
            youtube_videoid: "", 
            title: "",
            description: "", 
            thumbnail: ""
        })

        let apiKeyIndex = 0;
        state.apiKey = youtubeApiKey[apiKeyIndex];
        
        const searchAndAddVideoById = async () => {
            let url = "https://www.googleapis.com/youtube/v3/videos";
            const youtube_params = {
                part: "id,snippet", id:state.youtube_videoid, key: state.apiKey,
            }

            axios.get(url, {
                params : youtube_params
            })
            .then((response)=>{
                if (response.data.items.length !== 1) {
                    alert('잘못된 videoid입니다');
                } else {
                    let item = response.data.items[0];
                    state.title = item.snippet.title;
                    state.description = item.snippet.description;
                    state.thumbnail = item.snippet.thumbnails.medium.url;
                    addVideoToCollection();
                }
            })
            .catch((error)=> {
                if (error.response.status === 403) {
                    apiKeyIndex++;
                    state.apiKey = youtubeApiKey[apiKeyIndex];
                }
                searchAndAddVideoById();
                console.dir(error)
            })
        }

        const addVideoToCollection = async () => {
            const currentCollection = collections.value[state.selectedIndex];
            const currentVideos = store.state.videos.filter((v)=>v.collections_id === currentCollection.id);
            if (currentVideos.length >= 60) {
                alert('한 컬렉션에 저장할 수 있는 최대 영상의 수는 60개입니다.');
                return;
            }

            await addVideo({ 
                email : user.email,
                collections_id: currentCollection.id, 
                youtube_videoid: state.youtube_videoid, 
                title: state.title,
                description: state.description, 
                thumbnail: state.thumbnail,
            })
            router.replace({ name:"collections" })
        }

        const goToSearch = () => {
            router.replace({ name:"search" })
        }

        return { collections, state, searchAndAddVideoById, goToSearch }
    }
}
</script>

<style scoped>
.modal { display: block; position: fixed; z-index: 1; 
    left: 0; top: 0; width: 100%; height: 100%;
    overflow: auto; background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); }
.box { background-color: white; margin:80px auto;
    max-width: 300px; min-width: 100px; min-height: 200px;
    font: 13px "verdana";
    padding: 5px 5px 5px 5px;  }
.box div { padding: 0;  display: block;  margin: 10px 0 0 0; }
.box .heading { background: #33A17F; font-weight: 300;
    text-align: left; color: #fff;
    margin:0; padding: 10px; min-width:200px;
    max-width:500px; }
.box .player { background:white; }
.title { text-align: left; }
.pointer { cursor:pointer; }
</style>