const firebaseConfig = {
    apiKey: "AIzaSyANlgkStrvgCooAyZVhcRSa5nvGP0bfSaQ",
    authDomain: "vue--app-5f9d7.firebaseapp.com",
    databaseURL: "https://vue--app-5f9d7.firebaseio.com",
    projectId: "vue--app-5f9d7",
    storageBucket: "vue--app-5f9d7.appspot.com",
    messagingSenderId: "639379819501",
    appId: "1:639379819501:web:75290bd40067504d0775e4"
};

const youtubeApiKey =  [
    "AIzaSyDUerruMNn5wypVpTUZW7T97nB6aeXvAz0",
    "AIzaSyDVEpQMNbdz0akpiXdgCWqEviomyoSFd1Q",
    "AIzaSyByrky3abNWkAiZ-GC31wDpyuauVbHIXFo",
    "AIzaSyDm9Yv4UoB_CEgTlC8w_NgJAgPi_JZuNZc"
]

export { firebaseConfig, youtubeApiKey };