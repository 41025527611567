import { createApp } from 'vue'
import 'bootstrap/dist/css/bootstrap.css'
import App from './App.vue'
import router from './router'
import store from './store'

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { firebaseConfig } from '@/api.config'

firebase.initializeApp(firebaseConfig);

const app = createApp(App)
app.use(router)
app.use(store)
app.mount('#app')