<template>
    <div class="md mt-2 ml-2 mr-2">
        <h6 class="text-right">
        <small v-if="data.user !== null">
            {{data.user.email}}
            <a href="#" class="ml-3" @click="logoutProcess">로그아웃</a>
        </small>
        <small v-else class="text-right">
            <router-link to="/login">로그인</router-link>
        </small>
        </h6>
    </div>
</template>

<script>
import { reactive } from 'vue';
import firebase from "firebase/app";
import { useRouter } from 'vue-router';

export default {
    name : "LoginBadge",
    setup() {
        const router = useRouter();
        const data = reactive({ user: null });

        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                data.user = user;
            } else {
                data.user = null;
            }
        });

        const logoutProcess = ()=>{
            firebase.auth().signOut().then(() => {
                data.user = null;
                router.replace({ name:'home' });
            }).catch(function(error) {
                alert('로그아웃 실패 : '+ error);
            });
        }
        return { data, logoutProcess }
    }
}
</script>

<style>

</style>