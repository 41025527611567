import { createRouter, createWebHistory } from 'vue-router'
import firebase from "firebase/app";

import Home from '@/pages/Home.vue'
import Register from '@/pages/Register.vue'
import Login from '@/pages/Login.vue'
import Search from '@/pages/Search.vue'
import Collections from '@/pages/Collections.vue'
import AddCollection from '@/pages/collections/AddCollection.vue'
import PlayList from '@/pages/collections/PlayList.vue'

import PlayYoutube from '@/pages/search/PlayYoutube.vue'
import AddToMyCollection from '@/pages/search/AddToMyCollection.vue'
import AddVideoById from '@/pages/search/AddVideoById.vue'

const router = createRouter({
    history: createWebHistory(),
    routes : [
        { path: '/', name: 'home', component: Home, meta : { title : "MyTube 홈"} },
        { path: '/login', name: 'login', component: Login,  meta : { title : "MyTube 로그인"}},
        { path: '/register', name: 'register', component: Register,  meta : { title : "MyTube 사용자 등록"} },
        { 
            path: '/search', name: 'search', component: Search, meta : { title : "MyTube 검색"},
            children : [
                { path: 'addbyid', name:'addbyid', component: AddVideoById,  meta : { title : "MyTube 검색 - videoid로 추가"} },
                { path: 'add/:videoid', name:'search/add', component: AddToMyCollection, meta : { title : "MyTube 검색 - 컬렉션에 추가"} },
                { path: 'play/:videoid', name:'search/play', component: PlayYoutube,  meta : { title : "MyTube 검색 - 플레이어"} }
            ] 
        },
        { 
            path: '/collections', name: 'collections', component: Collections,  meta : { title : "MyTube 컬렉션" },
            children : [
                { path: 'add', name:'collections/add', component: AddCollection,  meta : { title : "MyTube 컬렉션 - 컬렉션 추가"} },
                { path: 'play/:index', name:'collections/play', component: PlayList, meta : { title : "MyTube 컬렉션 - 플레이어"} },
            ]
        },
    ]
})

router.beforeEach(async (to) => {
    const nearestRoute = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    document.title = nearestRoute.meta.title;
    const user = firebase.auth().currentUser;
    if (!user) {
        if (to.name !== "login" && to.name !== "register" && to.name !== "home") {
            if (to.name.indexOf("/") >= 0) {
                return { name:"login", query: { fromname:to.name.substring(0, to.name.indexOf('/')) } }
            } else {
                return { name:"login", query: { fromname:to.name } }
            }
        }
    }
})

export default router;