<template>
    <div class="container-fluid ml-0 mr-0">
        <div class="row border">
            <div class="input-group m-0">
                <input id="keyword" type="text" class="form-control rounded-0" name="keyword"
                 placeholder="검색" v-model="state.keyword" @keyup.enter="searchYoutube" />
                <button class="btn btn-light p-1 rounded-0" style="width:70px; border:solid 1px #CCCCCC"
                    @click="searchYoutube">
                    <span class="fa fa-search"></span>
                </button>
            </div>
        </div>
        <div class="mt-1 ml-0 mr-0 mb-5" >
            <div id="resultPanel" class="row" style="over-flow:auto;">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" 
                    v-for="(item) in state.searchResults.items" :key="item.id.videoId">
                    <div class="row mt-1 mb-1 border-top p-1">
                        <div class="col-5 col-sm-12">
                            <img :src="item.snippet.thumbnails.medium.url" style="cursor:pointer; width:100%;"
                                @click="goPlayYoutube(item.id.videoId)"/>
                        </div>
                        <div class="col-7 col-sm-12 mt-2 mb-2">
                            <h6 class="small_title" style="cursor:pointer;" @click="goPlayYoutube(item.id.videoId)"
                                v-html="item.snippet.title.length >= 50 ? item.snippet.title.substring(0,50) + '...' : item.snippet.title" />
                            <button class="badge badge-secondary mr-1" @click="goAddToMyCollection(item.id.videoId)">
                                내 컬렉션에 추가
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 text-center bg-dark" style="position:fixed; left:0px; bottom:0px; right:0px;">
            <div>
                <button class="btn btn-info m-1" style="width:80px;" @click="searchYoutube" title="다음 20개 조회">
                    <i class="fa fa-angle-down"></i> Next
                </button>
                <button class="btn btn-info m-1" style="width:80px;" @click="goTop" title="가장 앞으로 이동">
                    <i class="fa fa-angle-double-up"></i> Top
                </button>
                <button class="btn btn-info m-1" style="width:80px;" @click="goAddById" title="videoid를 이용해 직접 추가">
                    <i class="fa fa-plus-square"></i> Add
                </button>
            </div>
        </div>
        <router-view :searchResults="state.searchResults"></router-view>
    </div>
</template>

<script>
import { reactive, onUnmounted } from 'vue'
import axios from 'axios'
import { youtubeApiKey } from '@/api.config.js'
import { useRouter } from 'vue-router';

export default {
    name : 'Search',
    setup() {
        let apiKeyIndex = 0;
        const router = useRouter();
        const state = reactive({ 
            prev_keyword:"",
            keyword:"", 
            searchResults: {},
            isEndScroll : false,
            apiKey : ""
        })
        state.apiKey = youtubeApiKey[apiKeyIndex];
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            const elementHeight = document.body.scrollHeight - 
                window.innerHeight;

            if (scrollTop > elementHeight) {
                state.isEndScroll = true;
            }
        }

        window.addEventListener('scroll', handleScroll);

        onUnmounted(()=>{
            window.removeEventListener('scroll', handleScroll);
        })
        const goTop = ()=> {
            window.scrollTo(0,0)
        }

        const goPlayYoutube = (videoid) => {
            router.replace({ name:"search/play", params: { videoid }})
        }

        const goAddToMyCollection = (videoid) => {
            router.replace({ name:"search/add", params: { videoid }})
        }

        const searchYoutube = () => {
            const url = "https://www.googleapis.com/youtube/v3/search";
            const youtube_params = {
                part: "id,snippet", type:"video", q:state.keyword, 
                order: "viewCount", maxResults:20, pageToken:"", key: state.apiKey,
            }
            if (state.searchResults.nextPageToken) {
                youtube_params.pageToken = state.searchResults.nextPageToken;
            }

            axios.get(url, {
                params : youtube_params
            })
            .then((response)=>{
                if (state.prev_keyword !== state.keyword) {
                    state.searchResults = response.data;    
                    state.prev_keyword = state.keyword;    
                    state.isEndScroll = false;
                } else {
                    for (let i=0; i < response.data.items.length; i++) {
                        state.searchResults.items.push(response.data.items[i])
                    }
                    state.searchResults.nextPageToken = response.data.nextPageToken;
                    state.isEndScroll = false;
                }
            })
            .catch((error)=> {
                if (error.response.status === 403) {
                    apiKeyIndex++;
                    state.apiKey = youtubeApiKey[apiKeyIndex];
                }
                searchYoutube();
                console.dir(error)
            })
        }

        const goAddById = () => {
            router.replace({ name:"addbyid" })
        }

        return { state, searchYoutube, goTop, goPlayYoutube, goAddToMyCollection, goAddById }
    }
}
</script>

<style>
.small_title { font-size: 1rem; }
</style>